import { Link } from '@mui/material';
import clsx from 'clsx';

import styles from './Header.module.scss';

import { useStore } from '@app/store/useStore.hook';

import { renderRedirectToAuthServiceUrl } from '@app/auth/auth.utils';
import { Button } from '@app/components';
import { Breadcrumbs, Crumb } from '@app/components/breadcrumbs/Breadcrumbs';
import { UserHeaderInfo } from './user-header-info/UserHeaderInfo';
import { BackButton, BackButtonProps } from '@app/components/buttons/BackButton';

type Props = {
  className?: string;
  breadcrumbs?: Crumb[];
  backButton?: BackButtonProps;
};

export const Header: React.FC<Props> = ({ backButton, breadcrumbs, className }) => {
  const { currentUser: user } = useStore(({ currentUser }) => ({
    currentUser,
  }));

  return (
    <div className={clsx(styles.header, className)}>
      <div className="flex items-center">
        {backButton?.href ? (
          <BackButton href={backButton.href} text={backButton.text} />
        ) : breadcrumbs ? (
          <Breadcrumbs crumbs={breadcrumbs} />
        ) : null}
      </div>
      <div className="flex items-center">
        {user ? (
          <UserHeaderInfo user={user} />
        ) : (
          <Link href={renderRedirectToAuthServiceUrl()}>
            <Button variant="text" color="primary">
              Log In
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};
