import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC } from 'react';

import { Button } from './button/Button';
import { TypedLink } from '@app/router';

export interface BackButtonProps {
  href: string;
  text: string;
}

export const BackButton: FC<BackButtonProps> = ({ href, text }) => (
  <TypedLink to={href}>
    <Button
      className="font-normal text-gray-400 hover:text-gray-700"
      classes={{ startIcon: 'mr-[4px]' }}
      startIcon={<ArrowBackIcon />}
      variant="text"
    >
      {text}
    </Button>
  </TypedLink>
);
