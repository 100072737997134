import { useCallback, useEffect, useState } from 'react';
import { Control, Controller, FieldPath, FieldValues, Path } from 'react-hook-form';

import { OptionItem, Select } from '@app/components';
import { SelectChangeEvent } from '@mui/material';
import { useGetCalcStates } from '../api/sti-api.hooks';

interface Props<T extends FieldValues> {
  index: number;
  control: Control<T>;
  focus?: boolean;
  disabled?: boolean;
  onChange?: (e: SelectChangeEvent) => void;
  name?: Path<T>;
}

export const StateSelect = <T extends FieldValues>({
  index,
  control,
  focus = false,
  disabled,
  onChange,
  name,
}: Props<T>) => {
  const [open, setOpen] = useState(false);

  const { data: statesList = [], isLoading } = useGetCalcStates();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    setOpen(focus);
  }, [focus]);

  return (
    <Controller
      control={control}
      name={name || (`stateInputs.${index}.stateCode` as FieldPath<T>)}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          error={Boolean(error)}
          inputProps={{
            label: index === 0 ? 'State' : undefined,
            error: Boolean(error),
            inputSize: 'large',
          }}
          className="p-4"
          variant="outlined"
          placeholder="Select State"
          fullWidth
          disabled={isLoading || disabled}
          displayEmpty
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          renderValue={(value) => {
            if (!value) {
              return <span className="text-[#a9a9a9]">Select State</span>;
            }

            return <>{value}</>;
          }}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
        >
          <OptionItem value="" disabled>
            <span className="text-[#a9a9a9]">Select state</span>
          </OptionItem>
          {statesList.map((state) => (
            <OptionItem value={state.code} key={state.id}>
              {state.title}
            </OptionItem>
          ))}
        </Select>
      )}
    />
  );
};
