import styles from './Table.module.scss';
import clsx from 'clsx';
import React, { useCallback } from 'react';

import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  LinearProgress,
  Fade,
} from '@mui/material';

import { EBodyCellBG, TableColumn } from './TableTypes';
import { NotFound } from './components/not-found/NotFound';
import { TableLoader } from './components/table-loader/TableLoader';
import { Checkbox } from '@app/components';
import { TableCard } from '@app/components/table-card/TableCard';
import { clsxm } from '@app/styles/clsxm';
import { isNumber, isString } from 'lodash';

export interface TableProps<T> {
  id?: string;
  idKey?: string;
  notFoundMessage?: string;
  cols: TableColumn<T>[];
  tableData: T[];
  loading?: boolean;
  total?: number;
  currentPage?: number;
  pageSize?: number;
  selectedItems?: T[];
  selectableCells?: boolean;
  showSelectAll?: boolean;
  handleAllSelect?: (selectedItems: T[]) => void;
  handleCellSelect?: (selectedItem: T, isChecked: boolean) => void;
  rightHeaderContent?: React.ReactNode;
  leftHeaderContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  headerContent?: React.ReactNode;
  onPagination?: (page: number) => void;
  currentSort?: string;
  onSortChanged?: (newSort: string) => void;
  currentItem?: T;
  onItemSelect?: (item: T) => void;
  onSizeChange?: (newSize: number) => void;
  headerNameClassName?: string;
  subHeaderNameClassName?: string;
  tableRowClassName?: string;
  tableCellClassName?: string;
  wrapperClassName?: string;
  cardHeaderClassName?: string;
  tableHeadCellClassName?: string;
  hasMore?: boolean;
  onHasMoreClick?: () => void;
  hidePageSize?: boolean;
  customRowClassDynamic?: (rowData: T) => string;
  title?: string;
  pdf?: boolean;
  tableLayout?: 'auto' | 'fixed';
}

export function Table<T extends { [key: string]: any }>({
  id,
  idKey = 'id',
  notFoundMessage,
  cols,
  tableData = [],
  loading,
  leftHeaderContent,
  rightHeaderContent,
  footerContent,
  currentPage,
  total = 0,
  selectedItems,
  selectableCells,
  showSelectAll,
  handleAllSelect,
  handleCellSelect,
  pageSize = 10,
  onPagination,
  currentSort,
  onSortChanged,
  currentItem,
  onItemSelect,
  hasMore,
  onHasMoreClick,
  onSizeChange,
  headerContent,
  headerNameClassName,
  subHeaderNameClassName,
  tableHeadCellClassName,
  wrapperClassName,
  cardHeaderClassName,
  tableCellClassName,
  tableRowClassName,
  hidePageSize,
  customRowClassDynamic,
  title,
  pdf,
  tableLayout = 'auto',
}: React.PropsWithChildren<TableProps<T>>) {
  const renderCell = useCallback(
    ({ render, field, bold, minWidth, align }: TableColumn<T>, data: T, index: number) => {
      if (render) {
        return (
          <span
            className={clsxm(bold ? 'font-semibold' : undefined)}
            style={{ minWidth: pdf ? undefined : minWidth, textAlign: align }}
          >
            {render(data, index)}
          </span>
        );
      }

      if ((data && isNumber(data[field])) || isString(data[field])) {
        return (
          <span
            className={clsxm(bold ? 'font-semibold' : undefined)}
            style={{ minWidth: pdf ? undefined : minWidth, textAlign: align }}
          >
            {data[field]}
          </span>
        );
      }

      return null;
    },
    [pdf]
  );

  const renderSortIcon = useCallback((sortDirection?: string) => {
    if (!sortDirection) {
      return null;
    }
    return (
      <ChevronDownIcon
        className={clsx(styles.SortIcon, {
          [styles.SortIconDown]: sortDirection === 'asc',
        })}
      />
    );
  }, []);

  return (
    <>
      {title ? <Typography className="mb-2 font-semibold">{title}</Typography> : null}

      <TableCard
        total={total}
        wrapperClassName={wrapperClassName}
        cardHeaderClassName={cardHeaderClassName}
        pageSize={pageSize}
        currentPage={currentPage}
        leftHeaderContent={leftHeaderContent}
        rightHeaderContent={rightHeaderContent}
        footerContent={footerContent}
        headerContent={headerContent}
        onPagination={onPagination}
        hasMore={hasMore}
        onHasMoreClick={onHasMoreClick}
        onSizeChange={onSizeChange}
        hidePageSize={hidePageSize}
      >
        <div
          className={clsx(
            styles.TableContent,
            pdf && styles.pdf,
            !headerContent && !leftHeaderContent && !rightHeaderContent && styles.NoHeaderContent,
            loading ? styles.Loading : null
          )}
        >
          <TableContainer className={styles.TableContainer}>
            {!!loading && !hasMore && <TableLoader />}

            <MuiTable style={{ tableLayout }} id={id}>
              <TableHead className={styles.TableHead}>
                <MuiTableRow>
                  {cols.map((col) => {
                    const [sortField, sortDirection] = currentSort ? currentSort.split(',') : [];

                    const headAlign = col.headAlign || col.align;
                    return (
                      <TableCell
                        key={`${col.headerName}_${col.field}`}
                        align={headAlign}
                        width={col.width}
                        className={clsx(
                          styles.TableCell,
                          styles.TableHeadCell,
                          tableCellClassName,
                          {
                            [styles.TableHeadDisabledSort]: col.disableSort,
                            [styles.YellowBg]: col.headerCellBg === EBodyCellBG.YELLOW,
                            [styles.GrayBg]: col.headerCellBg === EBodyCellBG.GRAY,
                            [styles.WhiteBg]: col.headerCellBg === EBodyCellBG.WHITE,
                            [styles.StickyCell]: col.sticky,
                            [styles.StickyCellRight]: col.sticky === 'right',
                            [styles.StickyCellLeft]: col.sticky === 'left',
                          },
                          col.headCellClassName,
                          tableHeadCellClassName
                        )}
                        onClick={() => {
                          if (onSortChanged && !col.disableSort) {
                            let newDirection = 'asc';

                            if (sortField === col.field) {
                              if (sortDirection === 'asc') {
                                newDirection = 'desc';
                              } else if (!sortDirection) {
                                newDirection = 'asc';
                              } else if (sortDirection === 'desc') {
                                newDirection = '';
                              }
                            }
                            onSortChanged(newDirection ? `${col.field},${newDirection}` : '');
                          }
                        }}
                      >
                        <Typography variant="body2" className={clsx(styles.HeaderName, headerNameClassName)}>
                          {sortField && sortField === col.field && headAlign === 'right'
                            ? renderSortIcon(sortDirection)
                            : null}
                          {col.headerJSX || col.headerName}
                          {sortField && sortField === col.field && headAlign !== 'right'
                            ? renderSortIcon(sortDirection)
                            : null}
                        </Typography>
                        {col?.subHeaderName && (
                          <Typography variant="body2" className={clsx(styles.SubHeaderName, subHeaderNameClassName)}>
                            {col.subHeaderName}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </MuiTableRow>
              </TableHead>
              <TableBody>
                {showSelectAll && tableData.length > 0 && (
                  <MuiTableRow className={clsx(styles.TableBodyRow)}>
                    {cols.map((col, colIndex) => (
                      <TableCell
                        key={`${col.headerName}_${col.field}`}
                        className={clsx(
                          styles.TableCell,
                          {
                            [styles.StickyCell]: col.sticky,
                            [styles.StickyCellRight]: col.sticky === 'right',
                            [styles.StickyCellLeft]: col.sticky === 'left',
                          },
                          tableCellClassName
                        )}
                      >
                        {colIndex === 0 && handleAllSelect && (
                          <div className={styles.SelectAllWrapper}>
                            <Checkbox
                              checked={!!tableData.length && selectedItems?.length === tableData.length}
                              className={styles.Checkbox}
                              color="primary"
                              disableRipple
                              onClick={() => handleAllSelect(tableData)}
                            />
                            <Typography className={styles.SelectAllText} variant="subtitle2">
                              Select All
                            </Typography>
                          </div>
                        )}
                      </TableCell>
                    ))}
                  </MuiTableRow>
                )}

                {tableData.map((data, dataIndex) => (
                  <MuiTableRow
                    key={data[idKey] || dataIndex}
                    className={clsx(
                      styles.TableBodyRow,
                      tableRowClassName,

                      {
                        [styles.TableBodyRowSelectable]: onItemSelect,
                        [styles.TableBodyRowSelected]: currentItem ? currentItem[idKey] === data[idKey] : false,
                      },
                      customRowClassDynamic ? customRowClassDynamic(data) : false
                    )}
                    onClick={() => {
                      if (onItemSelect) {
                        onItemSelect(data);
                      }
                    }}
                  >
                    {cols.map((col, colIndex) => {
                      // FOR SELECTIONS ONLY  START

                      const makeCheck = selectableCells && colIndex === 0;
                      let checked = false;

                      if (makeCheck) {
                        const found = selectedItems?.findIndex((v) => v[idKey] === data[idKey]) ?? -1;
                        if (found > -1) {
                          checked = true;
                        }
                      }

                      // FOR SELECTIONS ONLY END

                      return (
                        <TableCell
                          key={`${col.headerName}_${col.field}`}
                          align={col.align}
                          onClick={col.disableClick ? (e) => e.stopPropagation() : undefined}
                          className={clsx(
                            styles.TableCell,
                            styles.TableBodyCell,
                            {
                              [styles.DisableClick]: col.disableClick,
                              [styles.YellowBg]: col.bodyCellBg === EBodyCellBG.YELLOW,
                              [styles.GrayBg]: col.bodyCellBg === EBodyCellBG.GRAY,
                              [styles.StickyCell]: col.sticky,
                              [styles.StickyCellRight]: col.sticky === 'right',
                              [styles.StickyCellLeft]: col.sticky === 'left',
                            },
                            col.bodyCellClassName,
                            tableCellClassName
                          )}
                        >
                          {selectableCells && colIndex === 0 ? (
                            <div className={styles.SelectAllWrapper}>
                              <Checkbox
                                checked={checked}
                                className={styles.Checkbox}
                                color="primary"
                                disableRipple
                                onClick={() => handleCellSelect && handleCellSelect(data, checked)}
                              />
                              {renderCell(col, data, dataIndex)}
                            </div>
                          ) : (
                            renderCell(col, data, dataIndex)
                          )}
                        </TableCell>
                      );
                    })}
                  </MuiTableRow>
                ))}
              </TableBody>
            </MuiTable>

            {!loading && !tableData.length && <NotFound notFoundMessage={notFoundMessage} />}
            <Fade in={loading && hasMore} unmountOnExit>
              <LinearProgress className={styles.LinearLoader} />
            </Fade>
          </TableContainer>
        </div>
      </TableCard>
    </>
  );
}
