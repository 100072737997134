import { EGroupLossRatioBy } from '@app/swagger-types';
import { OverrideLossRatioFilter } from '@app/swagger-override-types';

export const DEFAULT_GROUP_BY_LOSS_RATIO_VALUE = EGroupLossRatioBy.POLICY_NUMBER;

export const DEFAULT_LOSS_RATIO_FILTER_VALUES: OverrideLossRatioFilter = {
  groupBy: DEFAULT_GROUP_BY_LOSS_RATIO_VALUE,
  ncci: [],
  states: [],
  agencies: [],
  policyNumbers: [],
  insuredNames: [],
  effectiveDateStart: undefined,
  effectiveDateEnd: undefined,
  bindingMonthStart: undefined,
  bindingMonthEnd: undefined,
};

export const GROUP_BY_LOSS_RATIO_VALUES: EGroupLossRatioBy[] = [
  EGroupLossRatioBy.POLICY_NUMBER,
  EGroupLossRatioBy.NCCI,
  EGroupLossRatioBy.AGENCY,
  EGroupLossRatioBy.STATE,
  EGroupLossRatioBy.BINDING_MONTH,
];
