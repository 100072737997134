import { format, parse } from 'date-fns';
import { FC, useEffect, useState } from 'react';

import { Input, InputProps } from '../fields/input/Input';

interface Props extends InputProps {
  dateFormat: string;
  onChangeData: (data: Date) => void;
  dateValue: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  active?: boolean;
}

export const DateInput: FC<Props> = ({
  dateValue,
  dateFormat,
  onChangeData,
  active,
  contentClassName,
  onChange,
  error,
  errorMessage,
  onBlur,
  minDate,
  maxDate,
  ...props
}) => {
  const [value, setValue] = useState('');
  const [dateError, setDateError] = useState('');

  const formattedDateValue = dateValue ? format(dateValue, dateFormat) : '';

  useEffect(() => {
    setValue(formattedDateValue);
    setDateError('');
  }, [formattedDateValue]);

  const autoCorrectValue = (inputValue: string): string | null => {
    let parsedDate = parse(inputValue, dateFormat, new Date());

    // Manually handle two-digit years
    const year = parsedDate.getFullYear();
    if (year < 100) {
      parsedDate = new Date(parsedDate.setFullYear(year >= 50 ? 1900 + year : 2000 + year));
    }

    // If parsed correctly, return formatted date
    if (!isNaN(parsedDate.getTime())) {
      return format(parsedDate, dateFormat);
    }

    return null;
  };

  return (
    <Input
      {...props}
      value={value}
      error={Boolean(dateError) || error || Boolean(errorMessage)}
      errorMessage={dateError || errorMessage}
      onChange={(e) => {
        onChange?.(e);
        setValue(e.target.value);
        setDateError('');
      }}
      onBlur={(e) => {
        onBlur?.(e);

        if (value && formattedDateValue !== value) {
          const correctedValue = autoCorrectValue(value);
          console.log('correctedValue', correctedValue);
          if (correctedValue) {
            const newDate = parse(correctedValue, dateFormat, new Date());
            console.log('newUtcDate', correctedValue);
            if (minDate && newDate < minDate) {
              setDateError(`Min date is ${format(minDate, dateFormat)}`);
            } else if (maxDate && newDate > maxDate) {
              setDateError(`Max date is ${format(maxDate, dateFormat)}`);
            } else {
              setValue(correctedValue);
              onChangeData(newDate);
              setDateError('');
            }
          } else {
            setDateError(`Invalid format. Use ${dateFormat.toUpperCase()} instead.`);
          }
        }
      }}
      contentClassName={active ? 'border-yellow-dark' : contentClassName}
    />
  );
};
