import React, { useEffect, useRef } from 'react';
import { Control, FieldPath, Controller, FieldValues } from 'react-hook-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { Input, InputProps } from '../input/Input';

type Props<T extends FieldValues> = NumberFormatProps<InputProps> & {
  name: FieldPath<T>;
  control: Control<T>;
  onFieldBlur?: (e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFieldChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  useFormattedValue?: boolean;
  focus?: boolean;
};

export const ReactHookFormNumberField = <T extends FieldValues>({
  name,
  focus,
  control,
  onFieldBlur,
  onFieldChange,
  useFormattedValue,
  ...props
}: Props<T>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (focus) {
      setTimeout(() => {
        // click to set focus, '.focus()' does not work
        inputRef.current?.focus();
      }, 1);
    }
  }, [focus]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <NumberFormat
          customInput={Input}
          {...field}
          {...props}
          value={field.value as NumberFormatProps['value']}
          getInputRef={inputRef}
          error={Boolean(error)}
          onBlur={(e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            field.onBlur();
            onFieldBlur?.(e);
          }}
          onValueChange={(values) => {
            field.onChange(useFormattedValue ? values.formattedValue : values.value);
          }}
          onChange={onFieldChange}
        />
      )}
    />
  );
};
