import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { EQueryConfigName } from '@app/constants/query-config.const';
import { OverrideSubmissionStiAwsPrefillOutDto, ServerError } from '@app/swagger-override-types';
import {
  PageResponseSubmissionNamedInsuredOutDto,
  SubmissionStiFullPrefillOutDto,
  SubmissionStiFullPrefillInDto,
  SubmissionStiAwsPrefillInDto,
  PageResponseSubmissionOutDto,
  SubmissionNamedInsuredOutDto,
  SubmissionNamedInsuredInDto,
  SubmissionLossSummaryOutDto,
  SubmissionLossReviewOutDto,
  SubmissionGiCalcOutDto,
  SubmissionGiCalcInDto,
  SubmissionFileOutDto,
  PresignedUrlOutDto,
  SubmissionPatchDto,
  AbstractCalcOutDto,
  CalcErrorResponse,
  SubmissionOutDto,
  SubmissionInDto,
  ErrorResponse,
  EOutcomeType,
} from '@app/swagger-types';
import { PaginationQueryParams } from '@app/types/query-params.types';
import {
  FullCalcOutDtoResponse,
  AwsCalcOutDtoResponse,
  FullCalcInDto,
  AwsCalcInDto,
} from '@app/domain/sti/types/calc.types';
import {
  UploadSubmissionWorkbookInDto,
  ReplaceSubmissionFileInDto,
  BaseSubmissionFileInDto,
  GetSubmissionFileParams,
  SubmissionGiCalcParams,
  SubmissionFileInDto,
  SubmissionApi,
} from './submission.api';
import { showErrorToast, showSuccessToast } from '@app/utils/toast.utils';
import { LOADING_ESubmissionFileStatus_VALUES } from '../const/submission.const';
import { StiParams } from '@app/domain/sti/api/sti-api';

export const useGetSubmissionsQuery = (
  params: PaginationQueryParams,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    PageResponseSubmissionOutDto,
    (typeof EQueryConfigName.GET_SUBMISSIONS | PaginationQueryParams)[]
  >
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_SUBMISSIONS, params],
    queryFn: async () => {
      return await SubmissionApi.getSubmissions(params);
    },
    ...options,
  });
};

export const useGetSubmissionNamedInsuredsQuery = (
  params: PaginationQueryParams,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    PageResponseSubmissionNamedInsuredOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_NAMED_INSUREDS | PaginationQueryParams)[]
  >
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_SUBMISSION_NAMED_INSUREDS, params],
    queryFn: async () => {
      return await SubmissionApi.getNamedInsureds(params);
    },
    ...options,
  });
};

export const useGetSubmissionByIdQuery = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_BY_ID | typeof submissionId)[]
  >
) => {
  return useQuery({
    keepPreviousData: true,
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_BY_ID, submissionId],
    queryFn: async () => {
      return await SubmissionApi.getSubmissionById(submissionId);
    },
    refetchInterval: (query) => {
      const accordFile = query?.fileOutAccordApplicationDtos?.[0];
      const emrFile = query?.fileOutEmrDtos?.[0];
      const lossRunsFiles = query?.fileOutLossRunsDtos;

      if (
        (accordFile?.status && LOADING_ESubmissionFileStatus_VALUES.includes(accordFile.status)) ||
        (emrFile?.status && LOADING_ESubmissionFileStatus_VALUES.includes(emrFile.status)) ||
        (lossRunsFiles && lossRunsFiles.find((file) => LOADING_ESubmissionFileStatus_VALUES.includes(file.status)))
      ) {
        return 1000 * 7;
      }

      return false;
    },
  });
};

export const useGetSubmissionLossReviewQuery = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionLossReviewOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_LOSS_REVIEW | typeof submissionId)[]
  >
) => {
  return useQuery({
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_LOSS_REVIEW, submissionId],
    queryFn: async () => {
      return await SubmissionApi.getSubmissionLossReview(submissionId);
    },
  });
};

export const useGetSubmissionLossSummaryQuery = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionLossSummaryOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_LOSS_SUMMARY | typeof submissionId)[]
  >
) => {
  return useQuery({
    keepPreviousData: true,
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_LOSS_SUMMARY, submissionId],
    queryFn: async () => {
      return await SubmissionApi.getSubmissionLossSummary(submissionId);
    },
  });
};

export const useGetSubmissionFileMutation = (
  options?: UseMutationOptions<SubmissionFileOutDto, ServerError, BaseSubmissionFileInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (payload) => {
      return await SubmissionApi.getSubmissionFileById(payload);
    },
  });
};

export const useCreateSubmissionMutation = (
  options?: UseMutationOptions<SubmissionOutDto, ServerError, SubmissionInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.createSubmission(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSIONS]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};
export const useEditSubmissionMutation = <TContext = unknown>(
  submissionId: number,
  options?: UseMutationOptions<SubmissionOutDto, ServerError, SubmissionPatchDto, TContext>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.editSubmission(submissionId, dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSIONS]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreateNamedInsuredMutation = (
  options?: UseMutationOptions<SubmissionNamedInsuredOutDto, ServerError, SubmissionNamedInsuredInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.createNamedInsured(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_NAMED_INSUREDS]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

// use as 'mutationKey' for 'useUploadSubmissionFileMutation'
export enum EUploadSubmissFileMutationKey {
  UPLOAD_ACCORD = 'UPLOAD_ACCORD',
  UPLOAD_EMR = 'UPLOAD_EMR',
  UPLOAD_LOSS_RUNS = 'UPLOAD_LOSS_RUNS',
}

export const useUploadSubmissionFileMutation = (
  options?: UseMutationOptions<unknown, ServerError, SubmissionFileInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.uploadFileToSubmission(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, variables.submissionId]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useReuploadSubmissionFilesMutation = (
  options?: UseMutationOptions<unknown, ServerError, ReplaceSubmissionFileInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.reuploadFileToSubmission(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, variables.submissionId]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteSubmissionFilesMutation = (
  options?: UseMutationOptions<unknown, ServerError, BaseSubmissionFileInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.deleteFileToSubmission(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, variables.submissionId]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDownloadSubmissionUrlMutation = (
  options?: UseMutationOptions<PresignedUrlOutDto, ServerError, string | number>
) => {
  return useMutation({
    ...options,
    mutationFn: async (submissionId) => {
      return await SubmissionApi.getSubmissionDownloadUrl(submissionId);
    },
    onSuccess: async (data, variables, context) => {
      if (data.downloadUrl) {
        window.location.href = data.downloadUrl;
      } else {
        showErrorToast('File not found');
      }

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUploadSubmissionWorkbookMutation = (
  options?: UseMutationOptions<unknown, ServerError, UploadSubmissionWorkbookInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.uploadSubmissionWorkbook(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, variables.submissionId]);

      await options?.onSuccess?.(data, variables, context);

      showSuccessToast('File successfully uploaded');
    },
  });
};

export const useDownloadSubmissionFileUrlMutation = (
  options?: UseMutationOptions<PresignedUrlOutDto, ServerError, GetSubmissionFileParams>
) => {
  return useMutation({
    ...options,
    mutationFn: async (params) => {
      return await SubmissionApi.getFileDownloadUrl(params);
    },
    onSuccess: async (data, variables, context) => {
      if (data.downloadUrl) {
        window.location.href = data.downloadUrl;
      } else {
        showErrorToast('File not found');
      }

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCalculateSubmissionBySti = (
  submissionId: string,
  params: StiParams,
  options?: UseMutationOptions<FullCalcOutDtoResponse, ErrorResponse | CalcErrorResponse, FullCalcInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (dto) => await SubmissionApi.calculateSubmissionBySti(submissionId, dto, params),
    onSuccess: async (data, variables, context) => {
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCalculateSubmissionByAws = (
  submissionId: string,
  options?: UseMutationOptions<AwsCalcOutDtoResponse, CalcErrorResponse, AwsCalcInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (dto) => await SubmissionApi.calculateSubmissionByAws(submissionId, dto),
    onSuccess: async (data, variables, context) => {
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetSubmissionStiFullPrefill = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionStiFullPrefillOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_STI_FULL_PREFILL | typeof submissionId)[]
  >
) => {
  return useQuery({
    keepPreviousData: true,
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_STI_FULL_PREFILL, submissionId],
    queryFn: async () => await SubmissionApi.getSubmissionStiFullPrefill(submissionId),
  });
};

export const useGetSubmissionStiAwsPrefill = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    OverrideSubmissionStiAwsPrefillOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_STI_AWS_PREFILL | typeof submissionId)[]
  >
) => {
  return useQuery({
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_STI_AWS_PREFILL, submissionId],
    queryFn: async () => await SubmissionApi.getSubmissionStiAwsPrefill(submissionId),
  });
};

export const useUpdateSubmissionStiFullPrefill = (
  submissionId: string,
  options?: UseMutationOptions<SubmissionStiFullPrefillOutDto, ServerError, SubmissionStiFullPrefillInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (dto) => await SubmissionApi.updateSubmissionStiFullPrefill(submissionId, dto),
  });
};

export const useUpdateSubmissionStiAwsPrefill = (
  submissionId: string,
  options?: UseMutationOptions<OverrideSubmissionStiAwsPrefillOutDto, ServerError, SubmissionStiAwsPrefillInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (dto) => await SubmissionApi.updateSubmissionStiAwsPrefill(submissionId, dto),
  });
};

export const useGetSubmissionStiOutcome = (
  submissionId: string,
  outcomeType: EOutcomeType,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    AbstractCalcOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_STI_OUTCOME | typeof submissionId)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_STI_OUTCOME, submissionId, outcomeType],
    queryFn: async () => await SubmissionApi.getSubmissionStiOutcome(submissionId, { outcomeType }),
  });
};

export const useCalcSubmissionGi = (
  options?: UseMutationOptions<
    SubmissionGiCalcOutDto,
    ServerError,
    { dto: SubmissionGiCalcInDto; params?: SubmissionGiCalcParams }
  >
) => {
  return useMutation({
    ...options,
    mutationFn: async ({ dto, params }) => await SubmissionApi.calculateGi(dto, params),
  });
};
