import { FC, memo, MouseEvent, useState } from 'react';

import { SidebarRouteGroups } from '@app/models/router/';

import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { List, Popover, Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';
import { TypedLink } from '@app/router';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useStore } from '@app/store/useStore.hook';
import { NavSidebarLink } from './nav-sidebar-link/NavSidebarLink';
import { AppLogo } from '@app/assets/app-logo/AppLogo';
import { IconButton } from '@app/components';
import { ExternalAppsNavigation } from '@app/components/external-apps-navigation/AppsDropdown';

import styles from './NavSidebar.module.scss';

interface NavSidebarProps {
  open: boolean;
  badgesData?: Record<string, number>;
  navItems: SidebarRouteGroups[];
}

export const NavSidebar: FC<NavSidebarProps> = ({ open, badgesData, navItems }) => {
  const { currentUser } = useStore(({ currentUser }) => ({
    currentUser,
  }));

  return (
    <div className="flex h-full flex-col justify-between overflow-auto bg-secondary pb-[20px] pt-[10px]">
      <div>
        <div className="px-5 pb-16 pt-5">
          <TypedLink to={Routes.index}>
            <AppLogo noText={!open} />
          </TypedLink>
        </div>
        <List disablePadding>
          <div className={styles.ItemsWrapper}>
            {navItems
              .filter((group) => {
                const allowedRoutes = group.routes
                  // filter disabled routes
                  .filter((route) => !route.disabled)
                  // filter not allowed routes
                  .filter((route) => {
                    if (route.role) {
                      const hasValidRole = route.role.some((role) => currentUser?.groups.includes(role));

                      return hasValidRole;
                    }

                    return true;
                  });

                return allowedRoutes.length;
              })
              .map((group, groupIndex) => (
                <div key={groupIndex} className={styles.GroupWrapper}>
                  {open && <Typography className="text-xxs uppercase text-white/70">{group.groupName}</Typography>}
                  {group.routes.map((menuItem) => {
                    const badge = menuItem.badgeProp && badgesData ? badgesData[menuItem.badgeProp] : undefined;

                    const isRoleValid = menuItem.role
                      ? menuItem.role.some((role) => currentUser?.groups.includes(role))
                      : true;

                    if (menuItem.disabled || !isRoleValid) {
                      return null;
                    }

                    return (
                      <NavSidebarLink
                        key={menuItem.name}
                        name={menuItem.name}
                        to={menuItem.path}
                        icon={menuItem.icon}
                        badge={badge}
                        badgesData={badgesData}
                        tooltip={menuItem.tooltip}
                        isOpenSidebar={open}
                      />
                    );
                  })}
                </div>
              ))}
          </div>
        </List>
      </div>
      <OtherResourcesNavLinkDropdown isOpenSidebar={open} />
    </div>
  );
};

interface OtherResourcesNavLinkDropdownProps {
  isOpenSidebar: boolean;
}

export const OtherResourcesNavLinkDropdown: FC<OtherResourcesNavLinkDropdownProps> = memo(({ isOpenSidebar }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useHandler((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useHandler(() => setAnchorEl(null));

  const open = Boolean(anchorEl);
  const id = open ? 'other-resources-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick} className="w-full p-3">
        <NavSidebarLink
          name="Other Resources"
          to={''}
          icon={<LaunchOutlinedIcon />}
          isOpenSidebar={isOpenSidebar}
          preventClick
          className="border-t border-solid border-t-white/10"
          wrapperClassName="w-full"
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-2.5"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <ExternalAppsNavigation />
      </Popover>
    </>
  );
});
