import { Skeleton } from '@mui/material';
import { FC } from 'react';

import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { SubmissionCalculatorLastMileTab } from './SubmissionCalculatorLastMileTab';
import { SubmissionCalculatorStandardTab } from './SubmissionCalculatorStandardTab';
import { useSubmissionCalcPrefill } from '../hooks/useSubmissionCalcPrefill';
import { EOutcomeType } from '@app/swagger-types';

export enum ESubmissionSafeTierRatingTab {
  STANDARD = 'Standard',
  LAST_MILE = 'Last Mile',
}

interface Props {
  submissionId: string;
}

export const SubmissionSafeTierRatingTab: FC<Props> = ({ submissionId }) => {
  const { calcTab } = useSubmissionCalculatorFormsContext();

  const { isOutcomeGiDataFetching, isOutcomeFetching } = useSubmissionCalcPrefill(
    submissionId,
    calcTab === ESubmissionSafeTierRatingTab.STANDARD ? EOutcomeType.FULL_CALC : EOutcomeType.AWS_CALC
  );

  return isOutcomeGiDataFetching || isOutcomeFetching ? (
    <div className="flex flex-col gap-8">
      <Skeleton variant="rounded" width="100%" height={132} />
      <Skeleton variant="rounded" width="100%" height={600} />
    </div>
  ) : (
    <>
      {calcTab === ESubmissionSafeTierRatingTab.STANDARD && (
        <SubmissionCalculatorStandardTab submissionId={submissionId} />
      )}
      {calcTab === ESubmissionSafeTierRatingTab.LAST_MILE && (
        <SubmissionCalculatorLastMileTab submissionId={submissionId} />
      )}
    </>
  );
};
