import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { DefaultValues } from 'react-hook-form';

import { awsCalcDefaultValues, fullCalcDefaultValues } from '../forms/calc-default-values';
import { AwsCalcValidationSchema, FullCalcValidationSchemaType } from '../forms/calc.form';
import { AwsCalcOutDtoResponse, FullCalcOutDtoResponse } from '../types/calc.types';
import { CalcErrorResponse, SubmissionGiCalcOutDto } from '@app/swagger-types';
import { useRoleRequired } from '@app/auth/useRoleRequired.hook';
import { GROUPS_WITH_ACCESS_TO_ALTERNATIVE_RESULTS } from '../const/sti-access.const';

export const CalculatorFormsContext = createContext({
  // AWS CALC
  awsGiData: undefined as SubmissionGiCalcOutDto | undefined,
  awsCalcValues: awsCalcDefaultValues,
  awsCalcResult: undefined as AwsCalcOutDtoResponse | undefined,
  awsCalcSubmittedValues: undefined as AwsCalcValidationSchema | undefined,
  awsCalcErrors: null as CalcErrorResponse | null,
  shouldValidateAwsCalcForm: false,
  onChangeAwsGiData: (_: SubmissionGiCalcOutDto | undefined) => {},
  onChangeAwsCalcResult: (_: AwsCalcOutDtoResponse | undefined) => {},
  onChangeAwsCalcValues: (_: DefaultValues<AwsCalcValidationSchema>) => {},
  onChangeAwsCalcSubmittedValues: (_: AwsCalcValidationSchema | undefined) => {},
  onChangeAwsCalcErrors: (_: CalcErrorResponse | null) => {},
  // FULL CALC
  fullCalcResult: undefined as FullCalcOutDtoResponse | undefined,
  onChangeFullCalcResult: (_: FullCalcOutDtoResponse | undefined) => {},
  shouldValidateFullCalcForm: false,
  fullCalcValues: fullCalcDefaultValues,
  fullCalcSubmittedValues: undefined as FullCalcValidationSchemaType | undefined,
  onChangeFullCalcSubmittedValues: (_: FullCalcValidationSchemaType | undefined) => {},
  onChangeFullCalcValues: (_: DefaultValues<FullCalcValidationSchemaType>) => {},
  fullCalcErrors: null as CalcErrorResponse | null,
  onChangeFullCalcErrors: (_: CalcErrorResponse | null) => {},
});

interface Props {
  children: ReactNode;
}

export const CalculatorFormsProvider: FC<Props> = ({ children }) => {
  // AWS CALC
  const [awsGiData, setAwsGiData] = useState<SubmissionGiCalcOutDto | undefined>(undefined);
  const [awsCalcValues, setAwsCalcValues] = useState(awsCalcDefaultValues);
  const [awsCalcResult, setAwsCalcResult] = useState<AwsCalcOutDtoResponse | undefined>(undefined);
  const [shouldValidateAwsCalcForm, setShouldValidateAwsCalcForm] = useState(false);
  const [awsCalcSubmittedValues, setAwsCalcSubmittedValues] = useState<AwsCalcValidationSchema | undefined>(undefined);
  const [awsCalcErrors, setAwsCalcErrors] = useState<CalcErrorResponse | null>(null);
  // FULL CALC
  const [fullCalcValues, setFullCalcValues] = useState(fullCalcDefaultValues);
  const [shouldValidateFullCalcForm, setShouldValidateFullCalcForm] = useState(false);
  const [fullCalcResult, setFullCalcResult] = useState<FullCalcOutDtoResponse | undefined>(undefined);
  const [fullCalcSubmittedValues, setFullCalcSubmittedValues] = useState<FullCalcValidationSchemaType | undefined>(
    undefined
  );
  const [fullCalcErrors, setFullCalcErrors] = useState<CalcErrorResponse | null>(null);

  const onChangeAwsCalcValues = useCallback((values: DefaultValues<AwsCalcValidationSchema>) => {
    setAwsCalcValues(values);
    const { emr, companyName } = values;
    setFullCalcValues((prevValues) => ({ ...prevValues, emr, companyName }));
    setShouldValidateAwsCalcForm(true);
  }, []);

  const onChangeFullCalcValues = useCallback((values: DefaultValues<FullCalcValidationSchemaType>) => {
    setFullCalcValues(values);
    const { emr, companyName } = values;
    setAwsCalcValues((prevValues) => ({ ...prevValues, emr, companyName }));
    setShouldValidateFullCalcForm(true);
  }, []);

  const onChangeAwsCalcResult = useCallback((data: AwsCalcOutDtoResponse | undefined) => {
    setAwsCalcResult(data);
    setAwsGiData(undefined);
  }, []);

  const userHasAccessToGiData = useRoleRequired({ oneOf: GROUPS_WITH_ACCESS_TO_ALTERNATIVE_RESULTS });

  const value = useMemo(
    () => ({
      // AWS CALC
      awsGiData: userHasAccessToGiData ? awsGiData : undefined,
      awsCalcValues,
      shouldValidateAwsCalcForm,
      awsCalcSubmittedValues,
      awsCalcResult,
      onChangeAwsCalcValues,
      onChangeAwsCalcSubmittedValues: setAwsCalcSubmittedValues,
      onChangeAwsCalcResult,
      awsCalcErrors,
      onChangeAwsCalcErrors: setAwsCalcErrors,
      // FULL CALC
      fullCalcResult,
      fullCalcValues,
      shouldValidateFullCalcForm,
      fullCalcSubmittedValues,
      onChangeFullCalcValues,
      onChangeFullCalcSubmittedValues: setFullCalcSubmittedValues,
      onChangeFullCalcResult: setFullCalcResult,
      fullCalcErrors,
      onChangeFullCalcErrors: setFullCalcErrors,
      onChangeAwsGiData: setAwsGiData,
    }),
    [
      userHasAccessToGiData,
      awsGiData,
      fullCalcErrors,
      setFullCalcErrors,
      awsCalcErrors,
      setAwsCalcErrors,
      awsCalcValues,
      shouldValidateAwsCalcForm,
      onChangeAwsCalcValues,
      fullCalcValues,
      shouldValidateFullCalcForm,
      onChangeFullCalcValues,
      awsCalcResult,
      awsCalcSubmittedValues,
      setAwsCalcSubmittedValues,
      fullCalcSubmittedValues,
      setFullCalcSubmittedValues,
      fullCalcResult,
      setFullCalcResult,
      onChangeAwsCalcResult,
    ]
  );

  return <CalculatorFormsContext.Provider value={value}>{children}</CalculatorFormsContext.Provider>;
};

export const useCalculatorFormsContext = () => useContext(CalculatorFormsContext);
