import { FC } from 'react';

import { Collapse } from '@mui/material';

import { Table } from '../../../components/table/Table';
import { TableColumn } from '../../../components/table/TableTypes';
import { StiResponseDetailErrors } from '@app/swagger-types';
import { clsxm } from '@app/styles/clsxm';
import { Button } from '@app/components';

interface Props {
  errors: StiResponseDetailErrors[];
  open: boolean;
  onClose?: () => void;
  codeColumnLabel?: string;
  messageColumnLabel?: string;
  containerClassName?: string;
}

export const ErrorsTable: FC<Props> = ({
  errors,
  open,
  onClose,
  codeColumnLabel = 'Error Code',
  messageColumnLabel = 'Error Details',
  containerClassName = '',
}) => {
  const cols: TableColumn<StiResponseDetailErrors>[] = [
    {
      headerName: codeColumnLabel,
      field: 'code',
      align: 'center',
      headAlign: 'center',
      width: 200,
      render: ({ code }) => code || 'Unknown',
    },
    {
      headerName: messageColumnLabel,
      field: 'message',
      align: 'left',
      headAlign: 'left',
      render: ({ message }) => message || 'Unknown',
    },
  ];

  if (!errors.length) {
    return null;
  }

  return (
    <Collapse className={clsxm('w-full rounded bg-white px-8 py-10', containerClassName)} in={open}>
      <Table cols={cols} tableData={errors} hidePageSize />
      {onClose && (
        <div className="mt-6 flex justify-center">
          <Button
            className="w-60 rounded-sm border border-solid border-gray-700 py-2.5"
            variant="outlined"
            onClick={onClose}
          >
            Dismiss
          </Button>
        </div>
      )}
    </Collapse>
  );
};
