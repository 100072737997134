import { FC, useState } from 'react';

import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { SubmissionAwsCalculator } from '@app/domain/sti/widgets/SubmissionAwsCalculator';
import { SubmissionAwsResult } from '@app/domain/sti/widgets/SubmissionAwsResult';
import { CalcErrorResponse } from '@app/swagger-types';
import {
  useCalculateSubmissionByAws,
  useGetSubmissionByIdQuery,
  useCalcSubmissionGi,
} from '../api/submission.api.hooks';

interface Props {
  submissionId: string;
}

export const SubmissionCalculatorLastMileTab: FC<Props> = ({ submissionId }) => {
  const [awsCalcErrors, setAwsCalcErrors] = useState<CalcErrorResponse | null>(null);

  const { data: submissionData } = useGetSubmissionByIdQuery(submissionId);

  const { awsGiData, setCalcEditing, isCalcEditing, awsCalcResult, onChangeAwsCalcResult, onChangeAwsGiData } =
    useSubmissionCalculatorFormsContext();

  const { mutate: calcSubmissionGi, isLoading: isGiDataLoading } = useCalcSubmissionGi({
    onSuccess: (data) => onChangeAwsGiData(data),
  });

  const { mutateAsync, isLoading, data } = useCalculateSubmissionByAws(submissionId, {
    onSuccess: (response, variables) => {
      if (response.data) {
        calcSubmissionGi({
          dto: {
            company: response.data?.companyName,
            yearsInBusiness: variables.yearsInBusiness,
            emr: variables.emr,
            mostRecent: variables.mostRecent,
            previous: variables.previous || undefined,
            twoYearsPrevious: variables.twoYearsPrevious || undefined,
            stateInputs: variables.stateInputs,
          },
          params: { id: Number(submissionId), effectiveDate: submissionData?.effectiveDate },
        });
      }

      onChangeAwsCalcResult(response);
      setCalcEditing(false);
    },
    onMutate: () => setAwsCalcErrors(null),
    onError: (error) => setAwsCalcErrors(error),
  });

  return (
    <>
      {!isCalcEditing && awsCalcResult && awsCalcResult.data && !isLoading ? (
        <SubmissionAwsResult
          modelVersion={awsCalcResult.version?.model}
          responseId={awsCalcResult.responseId}
          warnings={awsCalcResult.warnings}
          isGiDataLoading={isGiDataLoading}
          errors={awsCalcResult.errors}
          setIsEditing={setCalcEditing}
          result={awsCalcResult.data}
          isLoading={isLoading}
          giData={awsGiData}
        />
      ) : (
        <SubmissionAwsCalculator
          calcSubmissionByAws={mutateAsync}
          awsCalcErrors={awsCalcErrors}
          submissionId={submissionId}
          isLoading={isLoading}
          awsCalcResult={data}
        />
      )}
    </>
  );
};
